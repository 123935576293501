@import "app-config";

.mobile_menu {
    box-shadow: 0 0 4px #999;
    background-color: $white_color !important;
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;

    .nav_row {
        height: 100%;
        display: flex;
        align-items: center;

        .menu_icon {
            width: 30px;
            font-size: 140%;
            color: $text_color;
            margin-right: 5px;
        }

        .logo {
            width: 190px;
            text-decoration: none;
            font-size: 120%;

            @media screen and (max-width: 375px) {
                width: 140px;
            }

            svg {
                fill: $theme_green !important;
                width: 100%;
                height: 36px;
                margin-top: 3px;
            }
        }

        .before_login {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .nav-link {
                font-size: 100%;
                padding: 0 10px;
                font-weight: 400;
                color: $text_color;

                &:hover {
                    color: $theme_green_hover;
                }
            }

            .login_btn {
                background-color: $theme-green;
                color: $white_color;
                padding: 4px 15px !important;
                border-radius: 20px;
                margin-right: 10px;
                text-decoration: none;

                &:hover {
                    background-color: $theme_green_hover;
                    color: $white_color;
                }
            }
        }

        .after_login {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .user_spinner {
                text-align: center;
                margin-right: 20px;

                .spinner-border {
                    width: 20px;
                    height: 20px;
                    border-width: 2px;
                    border-color: $theme_blue;
                    border-right-color: transparent;
                }
            }

            .bell_icon {
                position: relative;

                svg {
                    color: $theme_green;
                    font-size: 130%;
                }

                .notification {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: $theme_orange;
                    color: $white_color;
                    font-size: 65%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #fff;
                    position: absolute;
                    top: -6px;
                    left: 7px;

                }
            }

            .no_notify {
                cursor: inherit;

                svg {
                    color: $text_color;
                }
            }
        }
    }

    .back_drop {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 99;
    }

    .mobile_sidebar {
        width: 100%;
        max-width: 320px;
        height: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        background: $white_color;
        transition: .3s;

        @media screen and (max-width: 375px) {
            max-width: 240px;
        }

        .top_Sticky {
            position: relative;

            .post_btn {
                width: 100%;
                padding: 20px 0 20px 24px;
                border: 0;
                border-radius: 0;
                background-color: $theme_orange;
                color: $white_color;
                text-align: left;
            }

            .close_sidebar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 0;
                background-color: $white_color;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                color: $text_color;
                position: absolute;
                top: 50%;
                right: -17px;
                transform: translateY(-50%);

                svg {
                    font-size: 120%;
                }
            }
        }

        .side_nav {
            display: block !important;
            height: calc(100% - 90px);
            padding: 20px 0 30px;
            overflow: auto;

            .nav-link {
                width: 100%;
                padding: 10px 0 10px 24px;
                color: $text_color;
            }
        }
    }

    .mobile_sidebar.mobile_disable {
        visibility: hidden;
        opacity: 0;
        transform: translateX(-100%);
    }
}