@import "../app-config.scss";
@import "../common/common.scss";

.chat_container {
    position: fixed;
    width: 100%;
    height: 100%;

    .chat_grid {
        width: 300px;
        height: 100%;
        border-right: 1px solid #e5e5e5;


        @media screen and (max-width: 768px) {
            width: 100%;
            padding-top: 10px;
        }

        .message_title {
            width: 100%;
            height: 60px;
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            align-items: center;

            .messages {
                svg {
                    font-size: 70%;
                    margin: 2px 5px 2px 0;
                    color: $text_color;
                }
            }

            .notification {
                width: 18px;
                font-size: 60%;
                font-weight: bold;
                background: #edf2f7;
                color: #000;
                border-radius: 25px;
                padding: 1px 0;
                display: inline-block;
                text-align: center;
            }

        }

        .search_form {
            width: 100%;
            padding: 10px 15px 5px 15px;

            input {
                width: 100%;
                padding: 8px 10px;
                background: #f3f3f3;
                border-radius: 5px;
                font-size: 75%;
                border: 0;

                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        .message_list {
            width: 100%;
            height: calc(100% - 123px);
            overflow: auto;
            border-right: 1px solid #e5e5e5;
            padding: 5px 15px 40px;
            @include custom_scrollbar;

            .chat_list {
                width: 100%;
                padding: 8px;
                display: flex;
                align-items: center;
                flex-direction: row;
                border-radius: 5px;
                margin-bottom: 5px;
                cursor: pointer;
                position: relative;
                border: none;
                background: transparent;
                text-align: left;

                &:hover {
                    background: #f6f6fe;
                }

                .message_sender_img {
                    width: 36px;
                    height: 36px;
                    margin-right: 10px;
                    position: relative;

                    img {
                        width: 36px;
                        height: 36px;
                        overflow: hidden;
                        border-radius: 50%;
                    }

                    .is_online {
                        position: absolute;
                        font-size: 70%;
                        bottom: 0px;
                        right: -2px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: #67d391;
                        border: 2px solid #fff;
                    }
                }

                .message_sender_name {
                    display: flex;
                    flex-direction: column;

                    .unread {
                        font-weight: bold
                    }

                    .name {
                        width: 100%;
                        font-size: 80%;
                        color: #000;
                        padding-bottom: 2px;
                    }

                    .last_message {
                        width: 100%;
                        font-size: 65%;
                        color: #666;

                        .chat-doc {
                            font-size: 20px;
                            color: #bbb;
                        }
                    }
                }

                .last_seen {
                    position: absolute;
                    font-size: 70%;
                    top: 15px;
                    right: 10px;
                }
            }

            .chat_list.active {
                background: #f6f6fe;
            }
        }
    }

    .message_content {
        position: fixed;
        left: 610px;
        top: 60px;
        bottom: 0;
        right: 0;
        background: #fff;
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -o-transition: all .5s;

        .message-content {
            width: 100%;
            height: calc(100% - 130px);
            overflow: auto;
            padding: 15px 0;
            @include custom_scrollbar;

            @media screen and (max-width: 768px) {
                padding-bottom: 0;
                height: calc(100% - 115px);
            }

            .content-image {
                max-width: 300px;
                width: auto;
            }

            .sender-message {
                width: 100%;
                text-align: right;
                padding-bottom: 5px;
                display: flex;
                justify-content: flex-end;

                .content {
                    max-width: 60%;
                    background: #4897f4;
                    border-radius: 10px 10px 0;
                    color: #fff;
                    padding: 6px 10px;
                    font-size: 80%;
                    line-height: 150%;
                }
            }

            .reciever-message {
                width: 100%;
                text-align: left;
                padding-bottom: 5px;
                display: flex;
                justify-content: flex-start;

                .content {
                    max-width: 60%;
                    background: #edf2f9;
                    border-radius: 10px 10px 10px 0;
                    color: #5e6e82;
                    padding: 6px 10px;
                    font-size: 80%;
                    line-height: 150%;
                }


                .video_tag {
                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }
                }
            }

            .doc-pdf {
                color: red;
                font-size: 40px;
            }

            .doc-doc {
                color: #4897f4;
                font-size: 40px;
            }
        }

        @media screen and (max-width: 992px) {
            left: 300px;
        }

        .message_title {
            width: 100%;
            height: 60px;
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            align-items: center;

            .hide_chat_btn {
                display: none;
                margin-right: 15px;

                svg {
                    font-size: 120%;
                }

                @media screen and (max-width: 768px) {
                    display: block;
                }
            }

            .user_active {
                width: 36px;
                height: 36px;
                overflow: hidden;
                border-radius: 100%;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .user_name_status {
                display: flex;
                flex-direction: column;

                .name {
                    width: 100%;
                    font-size: 100%;
                    font-weight: 700;
                    color: #000;
                    padding-bottom: 2px;
                }

                .status {
                    width: 100%;
                    font-size: 65%;
                    color: #666;

                    .online {
                        background: #67d391;
                        width: 6px;
                        height: 6px;
                        margin-right: 6px;
                        border-radius: 100%;
                        display: inline-block;
                    }
                }
            }
        }

        .message-input {
            width: 100%;
            height: 60px;
            z-index: 1000;
            background: #fff;
            padding: 10px 15px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 768px) {
                position: sticky;
            }

            .attachment {
                cursor: pointer;
                background: transparent;
                border: none;

                svg {
                    height: 40px;
                    padding: 10px 5px;
                    width: 35px;
                    color: $text_color;
                }
            }

            input[type="text"] {
                width: 100%;
                height: 40;
                border: 1px solid #e5e5e5;
                padding: 10px;
                border-radius: 5px;
                font-size: 80%;

                &:focus {
                    outline: none;
                }
            }

            .message-send-btn {
                width: 40px;
                height: 40px;
                position: absolute;
                right: 16px;
                top: 11px;

                .send-btn {
                    width: 40px;
                    height: 40px;
                    color: red;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                }

                .message-send-btn-icon {
                    width: 40px;
                    height: 40px;
                    color: #4897f4;
                    padding: 10px;
                }
            }


        }

        @media screen and (max-width: 768px) {
            left: 0px;
            transform: translateX(-102%);
        }
    }

    @media screen and (max-width: 768px) {
        .message_content.show_message {
            transform: translateX(0);
        }
    }
}