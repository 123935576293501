@import "../app-config.scss";

.blog_section {
    .blogs {
        .blog_box {
            -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3);
            height: 100%;
            border-radius: 10px;

            .blog_image {
                width: 100%;
                overflow: hidden;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                .blog_link {
                    display: block;
                    width: 100%;
                    color: inherit;
                    text-decoration: none;

                    img {
                        width: 100%;
                        height: auto;
                        transition: 0.5s;
                    }
                }

                &:hover {
                    .blog_link {
                        img {
                            transform: scale(1.2);
                        }
                    }
                }
            }

            .blog_contents {
                padding: 25px 30px;

                .blog_title {
                    margin: 0;

                    .link_title {
                        text-decoration: none;
                        font-weight: 600;
                        font-size: 80%;
                        color: $text_color;

                        &:hover {
                            color: $theme_green;
                        }
                    }
                }

                .blog_date {
                    font-weight: 600;
                    font-size: 100%;
                    color: $text_color;
                    margin: 5px 0;
                }

                .blog_para {
                    font-size: 110%;
                    color: $text_light;
                    text-align: justify;
                }

                .blog_read_more {
                    display: block;
                    text-decoration: none;
                    color: $theme_green;
                    font-weight: 600;
                    font-size: 120%;
                    margin-top: 5px;
                    text-align: right;

                    &:hover {
                        color: $theme_green_hover;
                    }
                }
            }

        }
    }

    .page_pagination {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: -5px -8px;
        list-style-type: none;
        margin-top: 15px;

        .page_item {
            font-size: 14px;
            line-height: 20px;
            font-family: "Heebo", sans-serif;
            color: #151515;
            text-align: center;
            margin: 5px 5px;

            .page_link {
                color: #151515;
                border: 1px solid $theme_green_hover;
                border-radius: 50px;
                width: 40px;
                height: 40px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                &:hover {
                    background-color: $theme_green_hover;
                    color: $white_color;
                }
            }
        }
    }
}