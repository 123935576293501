@import "../app-config.scss";

.bottom_menu {
    height: 55px;
    position: fixed;
    z-index: 1000;
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 4px #999;

    @media screen and (min-width: 992px) {
        display: none;
    }

    .bottom_link {
        padding: 10px 0;
        flex: 1 1 0;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 85%;
        text-decoration: none;
        color: $text_color;

        svg {
            font-size: 160%;
        }
    }

    .bottom_link.active_panel {
        color: $theme_blue;

    }

    .active {
        color: $theme_blue;
    }
}