@import "app-config";
@import "./common/common.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    font-family: "Myriad-Pro" !important;
    transition: .1s;
    -webkit-transition: .1s;
    -moz-transition: .1s;
    -o-transition: .1s;

}

body,
html {
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02857em;
    font-weight: 400;
    transition: 0.3s;
    scroll-behavior: smooth;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include custom_scrollbar;

}

p {
    margin-bottom: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    display: inline-block;
    text-decoration: none;
}

.main-container {
    position: relative;
    width: 100%;
    margin-top: 60px;
}

.btn-block,
.full-width {
    clear: both;
    display: block !important;
    width: 100% !important;
}

.modal-content {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: none;

    .modal-header {
        background-color: #f9fafd;
        position: relative;

        .btn-close {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            background-color: #edf2f9;
            text-shadow: none;
            border-radius: 50%;
            -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            opacity: .5;


            &:hover {
                color: $black_color !important;
                background-color: #d8e2ef;
            }
        }

        .title {
            font-size: 120%;
            font-weight: 700;
            color: #344050;
        }
    }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btn_social {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;

    .btn_text {
        padding-left: 5px;
        text-transform: capitalize;
    }
}

.btn_facebook {

    background-color: #0d6efd !important;
    border: 1px solid #0d6efd !important;

    &:hover {
        background-color: #0d6efd !important;
        border: 1px solid #0d6efd !important;
    }
}

.form-error {
    color: $theme_red;
    margin-bottom: 0;
    font-size: 90%;
    margin-top: 3px;
}

.app-loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 9999;
    background-color: $brand-overlay-bg !important;

    svg {
        position: fixed;
        left: 0;
        right: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform: -webkit-translate(-50%, -50%);
        transform: -moz-translate(-50%, -50%);
        transform: -ms-translate(-50%, -50%);
        stroke: $theme_orange_hover !important;

        g {
            stroke-width: 4px !important;
            stroke: $theme_orange_hover !important;

            g {

                circle {
                    stroke: #fff !important;
                }

                path {
                    transition: auto;
                }
            }
        }

    }
}

.pac-logo::after {
    display: none !important;
}

.disable_btn {
    cursor: not-allowed !important;
}

.spinner_sm.spinner-border {
    height: 14px;
    width: 14px;
    border: 2px solid;
    border-right-color: transparent;
}

.home_spinner {
    text-align: center;

    .spinner-border {
        border: 2px solid $theme_blue;
        border-right-color: transparent;
    }
}

.user_spinner {
    width: 100%;
    height: 50px;
    padding-left: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .spinner-border {
        border: 2px solid $theme_blue;
        border-right-color: transparent;
    }
}

.submit_btn {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: $theme_blue;
    border: 0;

    &:hover {
        background-color: $theme_blue_hover;
        color: $white_color;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: auto;
    }
}

.cancel_btn {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    background-color: $theme_red;
    border: 0;
    margin-right: 20px;

    &:hover {
        background-color: $theme_red_hover;
        color: $white_color;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: auto;
    }
}

.back_drop {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
}

.z_index {
    z-index: 1001;
}

.modal-backdrop {
    z-index: 9999;
}

.modal {
    z-index: 9999;
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

.main_title {
    max-width: 600px;
    margin: 0 auto 60px;
    text-align: center;

    .title {
        color: $theme_green_hover;
        font-weight: 700;
        font-size: 250%;
        text-transform: capitalize;
        letter-spacing: 2px;
    }

    .subtitle {
        font-size: 110%;
        color: $text_light;
    }

    @media screen and (max-width : 576px) {
        .title {
            font-size: 190%;
        }

        .subtitle {
            font-size: 95%;
        }
    }
}

.section_padding {
    padding-top: 60px;

    @media screen and (max-width : 576px) {
        padding-top: 30px;
    }
}

.banners {
    width: 100%;
    height: 300px;
    position: relative;

    .banner_image {
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }
    }

    .banner_overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);

        .banner_content {
            margin: 0 auto;
            max-width: 600px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            color: $white_color;

            .title {
                font-weight: 700;
                font-size: 300%;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .subtitle {
                font-size: 100%;

                @media screen and (max-width: 576px) {
                    text-align: justify;
                    font-size: 90%;
                }
            }
        }

    }

    @media screen and (max-width: 576px) {
        height: 200px;

        .banner_overlay {
            .banner_content {
                padding: 10px;

                .title {
                    font-size: 180%;
                    letter-spacing: 0;
                }

                .subtitle {

                    text-align: justify;
                    font-size: 90%;
                }
            }
        }
    }
}

.lazyLoader {
    text-align: center;
    padding: 40px 0;

    .spinner-border {
        border: 2px solid $theme_blue;
        border-right-color: transparent;
    }
}

.default_btn {
    background-color: $theme_green !important;
    color: $white_color;
    font-size: 100%;
    border: 0;

    &:hover {
        background-color: $theme_green_hover !important;
    }
}

.sort_dropdown {
    width: 160px;

    button {
        width: 100%;
        height: 30px;
        background: transparent !important;
        border: 0;
        box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        color: $text_color !important;
        position: relative;

        .select_text {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            margin-left: 9.255em;
        }
    }

    .dropdown-menu {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .dropdown.show {
        button {
            transition: 0.5s;

            &::after {
                transform: rotate(180deg);
            }
        }
    }
}