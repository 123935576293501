@import "../app-config.scss";

.terms_condition_sec {
    .terms_condition {
        margin-bottom: 30px;

        .TC_title {
            font-size: 130%;
            font-weight: 600;
            color: $theme_green_hover;
        }

        .TC_paragraph {
            font-size: 100%;
            color: $text_light;
            text-align: justify;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}