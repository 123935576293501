@import "../app-config.scss";

.career_section {
    .career_details {
        .Job_overview {
            display: flex;
            align-items: center;

            .overview_content {
                padding-left: 24px;

                .overview_title {
                    font-weight: 600;
                    color: $text_color;
                }

                .overview_text {
                    font-size: 120%;
                    margin-right: 10px;
                    color: $text_color;

                    &:last-child {
                        margin-right: 0;
                    }

                    svg {
                        font-size: 100%;
                        margin-right: 3px;
                        margin-top: -4px;
                        color: $theme_green_hover;
                    }
                }
            }
        }

        .job_discription {
            margin-top: 40px;

            .discription_title {
                font-weight: 600;
                color: $theme_green_hover;
                text-transform: capitalize;
            }

            .discription_para {
                font-size: 110%;
                color: $text_light;
                text-align: justify;
            }

            .discription_list {
                margin-top: 16px;

                li {
                    display: flex;
                    align-items: center;
                    margin: 5px 0;

                    svg {
                        font-size: 120%;
                        margin-right: 3px;
                        color: $theme_green_hover;
                    }

                    p {
                        font-size: 110%;
                        color: $text_light;

                    }
                }
            }

            .job_apply_form {
                margin-top: 20px;

                .form-label {
                    font-size: 110%;
                    color: $text_light;
                    text-transform: capitalize;
                }

                .form-control {
                    font-size: 100%;
                    color: $text_light;

                    &::placeholder {
                        color: $text_light;

                    }
                }

                button {
                    width: 100%;
                }
            }
        }

        .summery_detail {
            position: sticky;
            top: 100px;

            .job_Summery {
                background-color: $theme_green_light;
                padding: 50px;
                border-radius: 3px;
                margin-bottom: 30px;

                .Summery_title {
                    font-weight: 600;
                    color: $theme_green_hover;
                    text-transform: capitalize;
                    margin-bottom: 20px;
                }

                .Summery_para {
                    font-size: 110%;
                    color: $text_light;
                    text-align: justify;
                }

                .Summery_list {

                    li {
                        display: flex;
                        align-items: center;
                        margin: 10px 0;

                        svg {
                            font-size: 120%;
                            margin-right: 3px;
                            color: $theme_green_hover;
                        }

                        p {
                            font-size: 110%;
                            color: $text_light;

                        }
                    }
                }
            }
        }
    }
}