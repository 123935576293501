@import "../app-config.scss";
@import "../common/common.scss";

.document_kyc {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 50px 20px 30px;

    .title {
        color: $black_color;
        font-size: 130%;
    }

    .para {
        font-size: 95%;
        color: $black_color;
    }

    .document_row {
        margin-top: 30px;

        .document_contain {
            background: #f5f7fb;
            border: 1px dashed #a0b0c8;
            border-radius: 5px;
            padding: 10px 10px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .pending_contain {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                svg {
                    color: $theme_orange;
                    font-size: 80%;
                    margin-bottom: 3px;
                    margin-right: 5px;
                }

                .pending {
                    color: $theme_orange;
                    font-size: 80%;
                }
            }

            .document_thumbnail {
                width: 90%;
                margin: 30px 0;
                background: $white_color;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 4px 4px #00000030;
            }

            .document_upload {
                width: 70px;
                position: relative;
                background: $white_color;
                font-size: 80%;
                border: 1px solid #e5e5e5;
                padding: 5px 10px;
                color: $text_color;

                svg {
                    font-size: 90%;
                    margin-bottom: 2px;
                }
            }
        }
    }
}