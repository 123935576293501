@import "../app-config.scss";

.top_arrow_box {
    position: fixed;
    bottom: -50px;
    right: 25px;
    opacity: 0;
    z-index: 9999;

    @media screen and (max-width: 992px) {
        right: 20px;
    }

    .top_arrow {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: $theme_blue;
        color: $white_color;
        cursor: pointer;
        text-align: center;
        display: grid;
        place-content: center;

        @media screen and (max-width: 992px) {
            width: 35px;
            height: 35px;
        }

        &:hover {
            background-color: $theme_blue_hover;
        }

        svg {
            font-size: 120%;

            @media screen and (max-width: 992px) {
                font-size: 100%;
            }
        }
    }
}

.top_arrow_box.trans_class {
    opacity: 1;
    bottom: 25px;
    transition: 0.5s;

    @media screen and (max-width: 992px) {
        bottom: 25px;
    }

    @media screen and (max-width: 600px) {
        bottom: 50px;
        right: 10px;
    }
}