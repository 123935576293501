@import "../app-config.scss";
@import 'common.scss';


.property_layout {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    position: relative;

    @media screen and (max-width: 992px) {
        padding-bottom: 55px;
        background-color: $white_color;

    }

    .tab_btn {
        background-color: $white-color;
        width: 290px;
        min-width: 290px;
        height: 100%;
        padding: 30px 0 0;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        position: fixed;
        top: 60px;
        left: 0;
        transition: 0.5s;

        @media screen and (max-width: 992px) {
            z-index: 999;
            transform: translateX(-100%);
            overflow: hidden;

        }

        .nav_links {
            width: 100%;
            height: calc(100% - 170px);
            padding-bottom: 30px;
            overflow: auto;
            @include custom_scrollbar;

            .tab_link {
                display: block;
                color: $text_light;
                text-decoration: none;
                font-size: 100%;
                padding: 15px 0px 15px 30px;
                border-left: 5px solid $white_color;

                svg {
                    margin-right: 15px;
                    font-size: 130%;
                }

                &:hover {
                    background-color: transparent;
                    color: $theme_blue;
                    border-left-color: $theme_blue;

                }
            }

            .disabled_link {
                cursor: not-allowed;
                color: $text_light;

                &:hover {
                    background-color: transparent;
                    color: $text_light;

                }
            }

            .tab_link.active {
                background-color: transparent;
                color: $theme_blue;
                border-left-color: $theme_blue;
            }

            .tab_link.disabled_details {
                background-color: transparent;
                cursor: not-allowed;
                color: $text_light;
            }
        }

    }

    .tab_btn.show_panel {
        transform: translateX(0);
        overflow: visible;
    }

    .property_tabs {
        width: calc(100% - 290px);
        margin-left: auto;
        height: 100%;
        background-color: $white_color;
        padding: 50px 20px 30px;

        @media screen and (max-width: 992px) {
            width: 100%;


        }
    }
}