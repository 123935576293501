@import "app-config";
@import 'common/common.scss';

.pac-container {
    z-index: 1002;
}

.property_search {
    position: relative;
    padding: 30px 0;

    .property_filter {
        background-color: $white_color;
        position: fixed;
        top: 60px;
        left: 0;
        z-index: 1002;
        width: 311px;
        min-width: 311px;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition: .5s;

        @media screen and (max-width: 992px) {
            width: 290px;
            min-width: 290px;
        }

        .filter_box {
            width: 100%;
            height: calc(100% - 110px);
            padding: 20px 15px;
            overflow: scroll;
            overflow-x: hidden;
            @include custom_scrollbar;
        }

        .filter_title {
            text-transform: uppercase;
            color: $text_light;
            font-size: 100%;
            margin-bottom: 15px;
        }

        .city_box {
            select {
                cursor: pointer;
            }
        }

        .title_box {
            @include d_space;
            color: $text_color;

            h6 {
                font-weight: 500;
                margin-bottom: 0;
            }

            p {
                cursor: pointer;
            }

            .close_icon {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid $text_color;
                padding: 3px;
                margin-left: 2px;
                margin-bottom: 1px;
            }
        }

        .filter_item_box {
            margin-top: 15px;

            .search_box {
                position: relative;

                .fa_times {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    cursor: pointer;
                }
            }

            .checkbox_row {
                .form-check {
                    display: flex;
                    align-items: center;

                    .form-check-input {
                        min-width: 15px;
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }

                    .form-check-label {
                        color: #5a5a5a;
                        font-size: 90%;
                        margin-left: 10px;
                        margin-top: 3px;
                        cursor: pointer;
                    }

                }
            }

            .price_range {
                @include d_space;
                margin-bottom: 30px;

                p {
                    color: $text_color;

                    span {
                        border: 1px solid $border_color;
                        border-radius: 5px;
                        padding: 3px 6px;

                        svg {
                            font-size: 80%;
                        }
                    }
                }
            }

            .sqft {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                color: $text_color;
            }
        }

        .apply_filter {
            background-color: $white_color;
            position: fixed;
            width: 311px;
            bottom: 0;
            left: 0;
            padding: 20px 15px;

            @media screen and (max-width: 992px) {
                width: 290px;
                min-width: 290px;
            }

            .btn {
                width: 100%;
                background-color: $theme_blue;
                border: 0;

                &:hover {
                    background-color: $theme_blue_hover;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .show_filter {
            transform: translateX(-100%);
        }
    }

    .property_results {
        background-color: $white_color;
        position: fixed;
        top: 60px;
        right: 0;
        z-index: 1002;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        transition: .5s;

        width: calc(100% - 311px);
        // margin-left: auto;

        @media screen and (max-width: 992px) {
            width: 100%;
            // margin: 25px 0;
            z-index: 1000;
            top: 102px;
        }

        .scroll_box {
            width: 100%;
            height: calc(100% - 40px);
            padding: 20px 0;
            overflow: scroll;
            overflow-x: hidden;
            @include custom_scrollbar;

            @media screen and (max-width: 992px) {
                height: calc(100% - 157px);
                padding: 0;
            }
        }

        .top_bar {
            margin-bottom: 30px;
            padding: 0 15px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 992px) {
                margin-bottom: 15px;
                margin-top: 32px;
            }

            .result_title {
                .heading {

                    color: $theme_blue;
                    font-size: 110%;
                    font-weight: 600;
                }
            }

            .filter_col {
                flex: 1;

                @media screen and (max-width: 992px) {
                    display: none;
                }

                .filter_box {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .list {
                        width: 26px;
                        height: 26px;
                        background-color: $btn_border;
                        color: $white_color;
                        padding: 5px;
                        border-radius: 5px;
                        margin: 0 10px;
                        cursor: pointer;
                    }

                    .menu {
                        width: 26px;
                        height: 26px;
                        background-color: $btn_border;
                        color: $white_color;
                        padding: 6px;
                        border-radius: 5px;
                        margin: 0 20px 0 10px;
                        cursor: pointer;
                    }

                    .active_btn {
                        background-color: $theme-green;
                        cursor: not-allowed;
                    }

                    .form_select {
                        width: 250px;
                    }

                    .sort {
                        display: flex;
                        align-items: center;
                        color: $text_color;
                        margin-right: 5px;

                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .card_row {
            padding: 0 15px;

            .list_col {
                background-color: $white_color;
                padding: 15px;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                text-decoration: none;
                position: relative;

                .img_col {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .owl-dots {
                        text-align: center;
                        -webkit-tap-highlight-color: transparent;
                        position: absolute;
                        z-index: 999999999;
                        bottom: 15px;
                        left: 50%;
                        bottom: 15px;
                        transform: translate(-50%, 0);
                        margin-top: 6px;
                        width: 100%;

                        .owl-dot {
                            span {
                                width: 10px;
                                height: 10px;
                                margin: 2px;
                                background: #000000;
                                display: block;
                                transition: 200ms ease;
                                border-radius: 30px;
                                opacity: 0.50;
                            }
                        }

                        .owl-dot.active span {
                            background-color: $theme_blue;
                            opacity: 1;
                        }
                    }

                }

                .content_col {
                    padding: 0 20px 0 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        font-size: 100%;
                        font-weight: 600;
                        margin-bottom: 0;
                        color: $text_color;
                        text-decoration: none;

                        &:hover {
                            color: $theme_green;
                        }
                    }

                    .price {
                        color: $theme-green;
                        font-size: 100%;
                        font-weight: 600;

                        .sub {
                            font-size: 80%;
                            color: $text_light;
                        }
                    }

                    .discription_content {
                        margin-top: 5px;

                        .discription_col {
                            display: flex;

                            @media screen and (max-width: 374px) {
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                            }

                            svg {
                                color: $theme_green;
                                margin-right: 10px;
                            }

                            .discription_box {
                                .label {
                                    font-size: 100%;
                                    color: $text_light;
                                }

                                .value {
                                    color: $text_color;
                                    font-weight: 600;
                                    font-size: 100%;
                                }
                            }
                        }
                    }

                }

                .list_row {
                    text-decoration: none;
                }

                .interested {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 1000;
                    background: $theme_green;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 4px 4px 4.7px;
                    cursor: pointer;

                    .full_heart,
                    .open_heart {
                        color: $white_color;
                    }

                }

                .interested_loader {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    z-index: 1000;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 4px 4px 4.7px;
                    cursor: pointer;

                    .spinner-border {
                        height: 22px;
                        width: 22px;
                        border: 2px solid $theme_green;
                        border-right-color: transparent;
                    }
                }
            }
        }

        .not_result {
            padding: 120px 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .not_img {
                width: 150px;
                height: 150px;
            }

            .not_content {
                .not_title {
                    font-weight: 600;
                    text-transform: capitalize;
                    color: $theme_green;
                }

                .not_para {
                    font-size: 120%;
                    color: $text_color;
                    margin-bottom: 10px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .filter_btn_box {
        display: none;
        height: 42px;
        position: fixed;
        z-index: 1001;
        background: $white_color;
        left: 0;
        right: 0;
        top: 60px;
        width: 100%;
        flex-direction: row;
        box-shadow: 0 0 4px #999;

        @media screen and (max-width: 992px) {
            display: flex;

            .btn {
                padding: 10px 0;
                background: #f9f9f9;
                flex: 1 1 0;
                flex-direction: row;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                color: $text_color;

                svg {
                    margin-right: 5px;
                }
            }

            .filter_btn {
                &::after {
                    content: '';
                    border-right: 1px solid #e6e6e6;
                    height: 25px;
                    right: 50%;
                    position: absolute;
                }
            }
        }
    }
}

.custom_sort {
    position: fixed;
    bottom: 0;
    left: 10px;
    right: 10px;
    z-index: 1003;
    background: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px #00000020;
    transform: translateY(102%);
    -webkit-transform: translateY(102%);
    -o-transform: translateY(102%);
    -moz-transform: translateY(102%);
    -ms-transform: translateY(102%);
    transition: transform .5s;
    -webkit-transition: transform .5s;
    -moz-transition: transform .5s;
    -o-transition: transform .5s;

    span {
        width: 100%;
        padding: 15px;
        display: block;
        cursor: pointer;
    }
}

.custom_sort.show_sort {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
}