@import "../app-config.scss";
@import "../common/common.scss";

.dashboard {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 50px 20px 30px;

    .title {
        color: $black_color;
        text-transform: capitalize;
    }

    .db_box {
        margin-top: 30px;

        .db_card {
            height: 100%;
            box-shadow: 0 0 45px rgba(0, 0, 0, .08);
            border: 1px solid transparent;
            transition: .5s;
            padding: 24px;
            border-radius: 2px;

            &:hover {
                border-color: rgba(0, 0, 0, .08);
                box-shadow: none;
            }

            .db_icon {
                svg {
                    font-size: 200%;
                    color: $theme_blue;
                }
            }

            .db_title {
                text-transform: capitalize;
                font-size: 130%;
                color: $text_light;
                margin-top: 15px;
            }

            .db_value {
                font-size: 110%;
                color: $theme_blue;
            }
        }

        .db_listed {
            .db_icon {
                svg {
                    color: $text_color;
                }
            }

            .db_value {
                color: $text_color;
            }
        }

        .db_rentout {
            .db_icon {
                svg {
                    color: $theme_green;
                }
            }

            .db_value {
                color: $theme_green;
            }
        }

        .db_inactive {
            .db_icon {
                svg {
                    color: $theme_orange;
                }
            }

            .db_value {
                color: $theme_orange;
            }
        }

        .tenant_card {
            box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
            border: 0;

            .tenant_header {
                padding: 20px;
                border-bottom: 1px solid $border_color;

                .tenant_title {
                    font-size: 110%;
                    font-weight: 600;
                    color: $text_color;
                    text-transform: capitalize;
                    margin: 0;
                }
            }

            .tenant_content {
                padding: 0;

                .tenant_list {
                    padding: 10px 20px;
                    border-bottom: 1px solid $border_color;
                    display: flex;
                    align-items: center;

                    &:last-child {
                        border: 0;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .name {
                        margin-left: 10px;
                    }

                    .price {
                        font-weight: 600;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}