@import "../app-config.scss";

@mixin item_box($with) {
    flex-basis: $with;
    background-color: $bg_color;
    text-align: center;
    font-size: 13px;
    padding: 8px 0;
    cursor: pointer;
}

@mixin bg_shadow {
    background-color: $white-color;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 2px;
}

@mixin d_center {
    display: grid;
    place-content: center;
}

@mixin d_space {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin custom_scrollbar {

    // scrollbar-width: thin;
    // scrollbar-color: #888 #f1f1f1;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }


}

@mixin input_size {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

@mixin input_size_label {
    color: #5a5a5a;
    font-size: 90%;
    margin-left: 13px;
    margin-top: 3px;
}

@mixin profile_section {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}