@import "../app-config.scss";
@import "../common/common.scss";

.tanent_container {
    position: fixed;
    height: calc(100% - 60px);
    width: auto;
    right: 290px;
    left: 290px;

    @media screen and (max-width: 992px) {
        left: 0;
    }

    @media screen and (max-width: 768px) {
        right: 0;
    }

    .message_list_container {
        width: 100%;
        height: 100%;
        border-right: 1px solid #e5e5e5;

        .message_title {
            width: 100%;
            height: 60px;
            padding: 15px;
            border-bottom: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            position: relative;

            .messages {
                font-size: 100%;
                font-weight: 700;
                color: $black_color;

                .title {
                    font-weight: 700;
                    font-size: 100%;
                    color: $black_color;
                    margin-bottom: 0;

                }
            }
        }

        .message_list {
            width: 100%;
            border-right: 1px solid #e5e5e5;
            height: calc(100% - 60px);
            overflow: auto;
            padding: 0 15px 5px;
            @include custom_scrollbar;

            @media screen and (max-width: 992px) {
                height: calc(100% - 125px);
            }

            .tenant_content.font_size * {
                font-size: 95%;
                color: $black_color;
            }

            .tenant_content {
                width: 100%;

                .tennat_box {
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    .tenant_list_title {
                        width: 100%;
                        margin-bottom: 3px;
                        border-radius: 10px;
                        padding: 10px 7px;
                        display: grid;
                        justify-content: space-between;
                        align-items: center;
                        grid-template-columns: 2fr 1fr 1fr 1fr 42px;
                        position: sticky;
                        background: #fff;
                        top: 0;
                        border-radius: unset;

                        .list_text {
                            color: $text_color;
                        }
                    }
                }
            }
        }
    }
}