@import "../app-config.scss";
@import "../common/common.scss";

.profile-dropdown {
    padding: 0 5px;
    height: 60px;
    width: auto;

    .btn {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: $white_color !important;
        border-color: transparent !important;
        color: $text_color;
        border-radius: 0 !important;
        padding: 3px 10px;

        img {
            width: 40px;
            height: 40px;
            min-width: 40px;
            max-width: 40px;
            border-radius: 50%;
        }

        .user_name {
            margin-bottom: 0;
            margin-left: 5px;
            font-size: 100%;
            font-weight: 400;
            color: $text_color;
            text-transform: capitalize;

            span {
                color: $text_color;

                svg {
                    transition: 0.3s;
                    color: $text_color;
                }
            }
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .dropdown_menu {
        width: 240px;
        background: #fff;
        left: -111px;
        padding: 0;
        border-radius: 0;
        border: 0;
        margin-top: 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
        display: block;
        top: 120%;
        transition: .3s;
        opacity: 0;
        visibility: hidden;
        border-bottom: 5px solid $theme_blue;

        @media screen and (max-width: 992px) {
            margin-top: -2px;
        }

        .dropdown-item {
            padding: 12px;
            font-size: 100%;
            font-weight: 400 !important;
            color: $text_color;
            transition: .3s;

            &:hover {
                background-color: $theme_green;
                color: $white_color;

            }
        }
    }

    .dropdown_menu.show {
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
}

.profile-dropdown.show {

    .btn {
        background-color: $theme_green !important;

        .user_name {
            color: $white_color !important;

            span {
                color: $white_color;

                svg {
                    transform: rotate(180deg);
                    color: $white_color;
                }
            }
        }
    }
}