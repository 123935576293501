@import "../app-config.scss";

.contact_info_section {
    .contact_info {
        .info_box_bg {
            background-color: $theme_green_light;
            padding: 16px;
            border-radius: 5px;
            height: 100%;

            .info_box {
                display: flex;
                align-items: center;
                border: 1px dashed rgba(0, 185, 142, .3);
                padding: 16px;
                background-color: $white_color;
                border-radius: 5px;

                .icon_box {
                    width: 45px;
                    height: 45px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background: $white_color;
                    border-radius: 50px;
                    border: 1px dashed #00b98e;
                    margin-right: 16px;

                    svg {
                        font-size: 160%;
                        color: $theme_green;
                    }
                }
            }

            .icon_label {
                font-size: 120%;
                color: $text_light;
            }
        }
    }
}

.contact_querys {

    @media screen and (max-width: 992px) {
        margin-top: 10px;

    }

    .address_map {
        border: 1px solid #dee2e6;
        padding: 5px;
        border-radius: 5px;
    }

    .contact_forms {
        border: 1px solid $border_color;
        padding: 20px;
        border-radius: 5px;

        .form-label {
            font-size: 100%;
            color: $text_light;
        }

        input::placeholder {
            color: $text_light;

        }

        textarea::placeholder {
            color: $text_light;
        }

        .send_btn {
            width: 100%;
            font-size: 100%;
            background-color: $theme_green;
            border: 0;

            &:hover {
                background-color: $theme_green_hover;
            }
        }
    }
}