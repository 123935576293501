@import "../app-config.scss";
@import "../common/common.scss";


.profile_sidebar {
    background-color: $white-color;
    min-width: 290px;
    width: 290px;
    height: 100%;
    padding: 30px 0 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: fixed;
    transform: translateX(-102%);
    transition: .5s;
    top: 60px;
    left: 0;
    z-index: 1005;

    .nav_links {
        width: 100%;
        height: calc(100% - 100px);
        padding-bottom: 30px;
        overflow: auto;
        @include custom_scrollbar;

        .tab_link {
            display: block;
            color: $text_light;
            text-decoration: none;
            font-size: 100%;
            padding: 15px 0px 15px 30px;
            border-left: 5px solid $white_color;

            svg {
                margin-right: 15px;
                font-size: 130%;
            }

            &:hover {
                background-color: transparent;
                color: $theme_blue;
                border-left-color: $theme_blue;

            }
        }

        .disabled_link {
            cursor: not-allowed;
            color: $text_light;

            &:hover {
                background-color: transparent;
                color: $text_light;

            }
        }

        .tab_link.active {
            background-color: transparent;
            color: $theme_blue;
            border-left-color: $theme_blue;
        }

        .tab_link.disabled_details {
            background-color: transparent;
            cursor: not-allowed;
            color: $text_light;
        }
    }

}

.profile_sidebar.show_sidbar {
    transform: translateX(0);
}