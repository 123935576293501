@import "../app-config.scss";
@import "../common/common.scss";

.offcanvas.offcanvas-end {
    top: 60px;
    border: 0;

    .offcanvas-title {
        font-size: 100%;
        font-weight: bold;
    }

    .offcanvas-body {
        @include custom_scrollbar;
    }

    .property_view {
        .property_contnet {
            .overview_details {
                .title {
                    margin-top: 10px;
                    margin-bottom: 5px;
                    color: $black_color;
                    font-weight: 600;
                }

                .location {
                    color: $text_light;

                    svg {
                        margin-right: 5px;
                    }
                }

                .rent_posted {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 10px;

                    .expected_rent {
                        font-size: 200%;
                        font-weight: 600;
                        color: $theme_green;

                        span {
                            font-size: 100%;
                            font-weight: 600;
                            margin-right: 3px;
                        }
                    }

                    .posted_date {
                        font-size: 90%;
                        color: $text_light;
                        margin-bottom: -10px;
                    }
                }

                .properties_details {
                    padding: 5px 0;

                    .title {
                        font-weight: 500;
                    }

                    .property_ul {

                        .label {
                            padding-bottom: 10px;
                            font-size: 100%;
                            color: $text_light;
                        }

                        .value {
                            color: $black_color;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .current_tenant_info {
        width: 100%;
        padding: 10px;
        background: $white_color;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1254901961);

        .tenant_profile {
            display: flex;
            align-items: center;
            justify-content: center;

            .current_tenant_image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1254901961);

                img {
                    width: 100%;
                }
            }

            .name {
                font-size: 100%;
                font-weight: 600;
                margin-left: 10px;
            }
        }

        .current_tenant {
            margin-top: 15px;

            .current_tenant_detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #f5f5f5;
                padding: 10px 0;

                .tenant_label {
                    color: $text_light;
                }

                .tenant_value {
                    color: #000;
                    font-weight: bold;
                }
            }
        }
    }
}