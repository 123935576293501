@import "../app-config.scss";

.choose_us {
    .choose_box {
        .choose_row {
            .choose_col {
                .feature {
                    background: $white_color;
                    border: 1px solid #eeeeee;
                    padding: 36px;
                    margin-right: -1px;
                    height: 100%;
                    position: relative;
                    z-index: 1;

                    .feature_icon {
                        margin-bottom: 20px;
                        color: $theme_green;

                        svg {
                            font-size: 300%;
                        }
                    }

                    .feature_content {
                        .feature_title {
                            margin-bottom: 16px;
                            font-size: 115%;
                            font-weight: 600;
                            color: $black_color;
                            text-transform: capitalize;
                        }

                        .feature_para {
                            color: $text_light;
                        }
                    }

                    &:hover {
                        background-color: $theme_green_hover;

                        .feature_icon {
                            svg {
                                color: $white_color;
                            }
                        }

                        .feature_content {
                            .feature_title {
                                color: $white_color;
                            }

                            .feature_para {
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }
    }
}