@import "../app-config.scss";

.maps_label {
    margin-bottom: 15px;

    .map_title {
        color: $text_color;
        font-weight: 500;
    }

    .map_para {
        color: $text_color;
        font-size: 90%;
    }
}

.map_box {
    border: 1px solid $border_color;
    padding: 5px;
    border-radius: 5px;
}

.map_box>div {
    border-radius: 5px;
}