@import "../app-config.scss";
@import "common.scss";

.grid_card {
    overflow: hidden;
    text-decoration: transparent;
    color: transparent;

    .card_img_box {
        overflow: hidden;

        img {
            transition: 0.5s;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }
        }

        .owl-dots {
            text-align: center;
            -webkit-tap-highlight-color: transparent;
            position: absolute;
            z-index: 999999999;
            bottom: 15px;
            left: 50%;
            bottom: 15px;
            transform: translate(-50%, 0);
            margin-top: 6px;
            width: 100%;

            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    margin: 2px;
                    background: #000000;
                    display: block;
                    transition: 200ms ease;
                    border-radius: 30px;
                    opacity: 0.50;
                }
            }

            .owl-dot.active span {
                background-color: $theme_blue;
                opacity: 1;
            }
        }
    }

    .card_link {
        text-decoration: transparent;
        color: $black_color;
        font-size: 100%;
        font-weight: 600;
        display: inline-block;
        line-height: 1.3;
        margin-bottom: 3px;

        &:hover {
            color: $theme_green;
        }
    }

    .card-title {
        color: $theme_green;
        font-size: 100%;
        font-weight: 700;
        margin-bottom: 0;

        .sub {
            font-size: 70%;
            color: $text_light;
        }
    }

    .card_box {
        color: $text_color;

        .icons {
            color: $theme_blue;
            background: #4898f62b;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            padding: 3px;
            margin-right: 2px
        }

        .floor_detail {
            @include d_space;

            p {
                margin-bottom: 0;
                font-size: 85%;
            }
        }

        .room_detail {
            @include d_space;

            p {
                margin-bottom: 0;
                font-size: 85%;
            }
        }
    }
}

.grid_box {
    position: relative;

    .interested {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1000;
        background: $theme_blue;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .open_heart,
        .full_heart {
            color: $white_color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        // .open_heart {
        //     z-index: 10;
        // }

        // .full_heart {
        //     z-index: 1;
        //     color: transparent;

        // }

        // &:hover {
        //     .full_heart {
        //         color: $white_color;
        //     }
        // }
    }

    // .interested.show_heart {
    //     .open_heart {
    //         z-index: 1;
    //         color: transparent;
    //     }

    //     .full_heart {
    //         color: $white_color;
    //         z-index: 10;
    //     }
    // }
}