@import "../app-config.scss";
@import "common.scss";

.tab_title {
    font-size: 115%;
    margin-bottom: 20px;
    font-weight: 500;
}

.tab_content {
    border: 1px solid $border_color;
    padding: 20px;
    border-radius: 5px;

    .form_label {
        font-size: 100%;
        color: $text_light;
    }

    .form_select {
        color: $text_color;
    }

    .form_input {
        color: $text_color;
    }

    .next_btn {
        margin-top: 20px;
        text-align: right;

        .btn {
            width: 100px;
            background-color: $theme_blue;
            border: 0;
            padding: 5px 0;
        }

        .save {
            background-color: $theme_green;
            margin-right: 20px;

            &:hover {
                background-color: $theme_green_hover;
            }
        }

        .next {
            background-color: $theme_blue;

            &:hover {
                background-color: $theme_blue_hover;
            }
        }
    }

    .inc_dec_box {
        height: 30px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dec_btn,
        .inc_btn {
            width: 35px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            top: 50%;
            background-color: #d0d0d0;
        }

        .increment_input {
            flex: 1;
            text-align: center;
            color: $text_color;
        }
    }

    .form_check {
        label {
            font-size: 100%;
            color: $text_color;
            cursor: pointer;
        }
    }


    .image_upload {
        background-color: #f8f8f8;
        border: 1px solid #e2e2e2;
        padding: 40px 0;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        label {
            cursor: pointer;
        }

        input {
            display: none;
        }
    }

    .preview_img {
        margin: 10px 0;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            object-position: center;
            margin: 5px;
            border-radius: 3px;
        }

    }

    .gallery_card {
        margin-bottom: 20px;

        img {
            width: 100%;
            max-height: 200px;
            object-fit: cover;
            object-position: center;
        }
    }

    .status_btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .btn {
            width: 140px;
            margin: 0 10px;
        }

        @media screen and (max-width: 540px) {
            flex-direction: column;

            .btn {
                margin: 10px 0;
            }
        }
    }
}

.delete_image_modal {
    .btn {
        width: 100px;
    }
}

.change_image_modal {
    .change_image {
        margin-bottom: 10px;
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 300px;
            height: 200px;
            border-radius: 5px;
            object-fit: cover;
            object-position: center;
        }
    }

    .btn_box {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .profile_pics {
            background-color: transparent;
            border-radius: 5px;
            color: $theme_blue;
            border: 1px solid $theme_blue;
            width: 100px;
            height: 30px;
            text-align: center;
            display: grid;
            place-content: center;
            margin-bottom: 0;
            margin-right: 20px;
            cursor: pointer;

            &:hover {
                background-color: $theme_blue;
                color: $white_color;
            }

            input {
                display: none;
            }
        }
    }
}