@import "../app-config.scss";

.privacy_policy {
    .privacy_policy_box {
        .privacy_policy_item {
            margin-top: 20px;

            .privacy_title {
                font-size: 130%;
                font-weight: 600;
                color: $theme_green_hover;
            }

            .privacy_para {
                font-size: 100%;
                color: $text_light;
                margin-bottom: 10px;
                text-align: justify;
            }
        }
    }
}