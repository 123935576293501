@import "../app-config.scss";

.favourite {
    padding: 20px;

    .grid_card {
        position: relative;

        .sub {
            margin-left: 3px;
        }
    }

    .interested {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1000;
        background: $theme_blue;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 4px 4px 4.8px;

        svg {
            color: $white_color;
            cursor: pointer;
        }
    }

    .property_details {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .property_item {
            display: flex;
            align-items: center;

            .icons {
                width: 20px;
                height: 20px;
                font-size: 80%;
                position: relative;
                margin-right: 3px;
                color: $theme_blue;
                background: #ecf0fe;
                border-radius: 100%;
                padding: 5px 4px;
                text-align: center;
            }

            .property_value {
                color: $text_color;
                font-size: 90%;
            }
        }
    }
}