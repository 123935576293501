@import "app-config";

.site-nav {
    box-shadow: 0 1px 5px #00000025;
    background-color: $white_color !important;
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;

    @media screen and (min-width: 992px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .container-fluid {
        padding-top: 0;
        padding-bottom: 0;

        .navbar-brand {
            padding-top: 0;
            padding-bottom: 0;

            svg {
                fill: $theme_green !important;
                width: 190px;
                height: 36px;
            }
        }

        #site-nav-items {
            .navbar-nav {
                .nav-link {
                    font-size: 100%;
                    padding: 0 10px;
                    font-weight: 400;
                    color: $text_color;

                    &:hover {
                        color: $theme_green_hover;
                    }

                }

                .login_btn {
                    background-color: $theme-green;
                    color: $white_color;
                    padding: 4px 15px !important;
                    border-radius: 20px;
                    margin-right: 10px;

                    &:hover {
                        background-color: $theme_green_hover;
                        color: $white_color;
                    }
                }

                .post_btn {
                    background-color: $theme_orange;
                    color: $white_color;
                    padding: 4px 15px !important;
                    border-radius: 20px;

                    &:hover {
                        background-color: $theme_orange_hover;
                        color: $white_color;
                    }
                }

                .after_login_dropdown {
                    width: 104px;

                    .user_spinner {
                        text-align: center;

                        .spinner-border {
                            width: 25px;
                            height: 25px;
                            border-width: 2px;
                            border-color: $theme_blue;
                            border-right-color: transparent;
                        }
                    }
                }


                .bell_icon {
                    position: relative;

                    svg {
                        color: $theme_green;
                        font-size: 130%;
                    }

                    .notification {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        background-color: $theme_orange;
                        color: $white_color;
                        font-size: 65%;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #fff;
                        position: absolute;
                        top: -6px;
                        left: 7px;
                        animation: notificationPulse 2s infinite;

                    }
                }

                .no_notify {
                    cursor: inherit;

                    svg {
                        color: $text_color;
                    }
                }
            }

            .before_login {
                .nav-link {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}

@keyframes notificationPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.9);
        box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
        box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(2255, 152, 0, 0);
        box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
}