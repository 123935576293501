@import "../app-config.scss";

.read_all {
    cursor: pointer;
    background-color: $theme_green;
    color: $white_color;
    border-radius: 5px;
    padding: 1px 10px;
    font-size: 90%;

    &:hover {
        background-color: $theme_green_hover;
    }
}

.notification_box {
    .notification_body {
        background-color: #edf2f9;

        .notification_items {
            border-bottom: 1px solid $border_color;
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            &:first-child {
                border-top: 1px solid $border_color;
            }

            &:hover {
                background-color: #e7e9ed;
            }

            .notification_image {
                margin-right: 10px;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }

            .notification_content {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }
}