@import '../app-config.scss';
@import 'common.scss';

.cities_sec {
    // background-color: $bg_color;
    // padding-top: 60px;

    .cities_link_box {
        margin: 0;
        padding: 0;

        li {
            list-style: none;

            .cities_title {
                font-weight: 500;
                color: $text_color;
                margin-bottom: 20px;
                font-size: 120%;

                svg {
                    margin-left: -5px;
                }
            }

            .cities_link {
                display: block;
                text-decoration: none;
                margin: 5px 0;
                color: $text_light;

                &:hover {
                    color: $theme_green;
                }
            }
        }
    }

    .view_all {
        text-align: right;
        margin-top: 10px;

        .view_all_link {
            text-decoration: none;
            color: $text_color;
            font-weight: 500;

            &:hover {
                color: $theme_green_hover;
            }

            svg {
                margin-bottom: 2px;
                margin-left: -3px;
            }
        }
    }
}