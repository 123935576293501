@import "../app-config.scss";

.auth_container {
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #f6f6fb;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 992px) {
        height: calc(100vh - 110px);
    }

    .custom_card {
        padding: 0 15px;
        display: flex;
        width: 100%;
        max-width: 400px;

        .card_body {
            width: 100%;
            background: $white_color;
            padding: 20px;
            border-radius: 3px;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            border: 0;
            box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);

            .card_title {
                font-weight: 600;
                padding-bottom: 18px;
                margin-bottom: 0;
                color: $text_color;
            }

            .form_group {
                position: relative;
                padding-bottom: 18px;

                .form-label {
                    color: $theme_blue;
                    padding: 0 5px;
                    position: absolute;
                    top: -12px;
                    left: 6px;
                    background: $white_color;
                    font-size: 90%;

                    .change_mobile,
                    .resend_otp {
                        color: $text_light;
                        cursor: pointer;
                        font-size: 90%;
                        text-decoration: none;

                        &:hover {
                            color: $black_color;
                        }
                    }

                    .otp_counter {
                        cursor: inherit;
                        color: $theme_blue;
                    }
                }

                .form-control {
                    &:focus {
                        color: $text_color;
                        border-color: $theme_blue;
                        outline: 0;
                        box-shadow: none;
                    }
                }

                .eye_box {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    cursor: pointer;
                    color: $theme_blue;
                }

                .react-tel-input {
                    .form-control {
                        width: 100%;
                    }
                }
            }

            .submit_login {
                border-radius: 3px;
                width: 100%;
                background-color: $theme_blue;
                color: $white_color;
                font-size: 100%;

                &:hover {
                    background-color: $theme_blue_hover;
                }

                .spinner-border {
                    height: 16px;
                    width: 16px;
                    border: 2px solid;
                    border-right-color: transparent;
                }
            }

            .otp_password_tab {
                text-align: left;

                .form-check {
                    .form-check-input {
                        cursor: pointer;
                    }

                    .form-check-label {
                        font-size: 100%;
                        color: $text_light;
                        cursor: pointer;
                    }
                }
            }

            .bottom_box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 20px;

                .bottom_text {
                    font-size: 100%;
                    color: $text_color;
                }

                .bottom_link {
                    font-size: 100%;
                    color: $theme_blue;
                    text-decoration: none;
                    margin-left: 5px;
                }
            }
        }

        .form_error {
            text-align: left;
            color: #dc3545;
            font-size: 90%;
        }
    }
}