@import 'app-config';
@import 'common/common.scss';

.home-banner {
    width: 100%;
    background-image: url("../../../public/assets/img/banner/banner-01.jpg");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: calc(100vh - 60px);
    position: relative;

    @media screen and (max-width: 992px) {
        height: calc(100vh - 115px);

    }

    @media screen and (max-width: 768px) {
        height: calc(100vh - 170px);

    }

    .banner-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);


        .banner-inner-content {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: 992px) {
                top: 60%;
                transform: translateY(-60%);
            }

            @media screen and (max-width: 768px) {
                top: 50%;
                transform: translateY(-50%);
            }

            .banner-heading {
                width: 100%;
                text-align: center;
                color: $white_color;
                font-size: 400%;
                font-weight: bold;

                @media screen and (max-width: 600px) {
                    font-size: 200%;
                }
            }

            .banner-property-filter {
                background-color: transparent;
                width: 80%;
                display: inline-block;
                border-radius: 3px;
                margin-top: 20px;

                @media screen and (max-width: 992px) {}

                .home_nav_tabs {
                    display: flex;
                    justify-content: center;
                    border: 0;

                    .nav-item {
                        button {
                            width: 120px;
                            border: 0;
                            background: $white_color;
                            color: $text_color;
                            border-radius: 0;

                            &:hover {
                                color: $white-color;
                                background-color: $theme_blue;
                                ;
                            }
                        }

                        &:first-child button {
                            border-top-left-radius: 5px;
                        }

                        &:last-child button {
                            border-top-right-radius: 5px;
                        }

                        .active {
                            background-color: $theme_blue;
                            color: $white_color;
                        }

                    }
                }

                .filter_container {
                    background: $white_color;
                    border: 1px solid $border-color;
                    border-radius: 5px;
                    padding: 15px 0px 15px 20px;

                    @media screen and (max-width: 992px) {
                        border-radius: 5px;
                        padding-right: 20px;
                    }

                    .filter_row {
                        height: 50px;
                        align-items: center;

                        @media screen and (max-width: 992px) {
                            height: 100%;
                        }


                        .form_select {
                            border: 0;
                            font-size: 100%;
                            height: 50px;
                            box-shadow: 0 3px 0 #00000050;
                            border-radius: 5px;
                            background: transparent;
                            cursor: pointer;
                            position: relative;
                            z-index: 1;

                            option {
                                color: $text_color;
                            }

                            @media screen and (max-width: 992px) {}

                        }

                        .check_icon {
                            width: 21px;
                            height: 21px;
                            border-radius: 50%;
                            background-color: $theme_blue;
                            color: $white_color;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 15px;

                            svg {
                                transition: 0.3s;
                            }
                        }

                        .move_icon {

                            svg {

                                transform: rotate(180deg);
                            }
                        }

                        .search_input {
                            border: 0;
                            font-size: 100%;
                            height: 50px;
                            box-shadow: 0 3px 0 #00000050;
                            border-radius: 5px;
                            padding-right: 25px;

                            @media screen and (max-width: 992px) {}
                        }

                        .search_input.place_holder::placeholder {
                            color: #dc3545 !important;
                        }

                        .search_input.place_holder {
                            box-shadow: 0 3px 0 #dc3545 !important;
                        }

                        .search_btn {
                            width: 46px;
                            height: 46px;
                            border: 0;
                            border-radius: 50% !important;
                            background: $theme_blue;
                            position: absolute;
                            top: 50%;
                            left: 42%;
                            transform: translate(-50%, -50%);

                            &:hover {
                                background: $theme_blue_hover;
                            }

                            .search_icon {
                                font-size: 160%;
                                color: $white_color;
                            }

                            @media screen and (max-width: 992px) {
                                position: inherit;
                                top: inherit;
                                left: initial;
                                transform: inherit;
                                height: inherit;
                                width: 100%;
                                border-radius: 5px !important;
                            }
                        }

                        .standalone_search_col {
                            position: relative;

                            .clear_locality {
                                position: absolute;
                                top: 15px;
                                right: 15px;

                                svg {
                                    cursor: pointer;
                                }
                            }
                        }

                    }

                    .filter_types {
                        padding: 5px 7px;

                        .search_btn {
                            @media screen and (max-width: 992px) {
                                width: 100%;
                                border-radius: 0 !important;
                            }
                        }


                        .types_label {
                            font-size: 14px;
                            margin-left: 4px;
                        }

                        .type_select {
                            border-radius: 0;
                            background-color: transparent;
                            font-size: 14px;
                        }

                        .new_project {
                            display: flex;
                            border: 1px solid $border-color;
                            justify-content: center;
                            align-items: center;
                            padding: 2.5px 0;

                            @media screen and (max-width: 768px) {
                                justify-content: start;
                                padding-left: 12px;
                            }

                            input {
                                border-radius: 0;
                                margin-top: 6px;
                            }
                        }
                    }

                    .error_input {
                        color: #dc3545 !important;
                        box-shadow: 0 3px 0 #dc3545 !important;
                    }

                }
            }
        }
    }
}

.about_sec {
    // padding-top: 60px;
    background-color: $white_color;

    .about_content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            align-items: center;
            text-align: center;
        }

        .about_title {
            color: $black_color;
            font-weight: 700;
            margin-bottom: 1.5rem;
        }

        .about_para {
            color: $text_color;
            margin: 1.5rem 0;

            @media screen and (max-width: 768px) {
                margin: 0;
            }
        }

        .about_btn {
            border: 0;
            background-color: $theme_green;
            color: $white_color;
            padding: 0;
            width: 200px;
            height: 40px;
            border-radius: 50px;
            margin-top: 1.5rem;

            &:hover {
                background-color: $theme_green_hover;
            }
        }
    }
}

.property_types {
    // padding-top: 60px;

    .property_sector {
        .property_row {
            // margin: 20px 0 60px;

            // @media screen and (max-width: 768px) {
            //     margin-bottom: 20px;
            // }

            .property_col {
                .property_link {
                    display: block;
                    background-color: $theme_green_light;
                    padding: 16px;
                    border-radius: 5px;
                    text-align: center;
                    text-decoration: none;

                    .property_box {
                        background-color: $white_color;
                        border-radius: 5px;
                        padding: 24px;
                        transition: .5s;
                        cursor: pointer;

                        .property_img {
                            padding: 15px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            background: $white_color;
                            border-radius: 50px;
                            border: 1px dashed $theme_green;
                            margin-bottom: 16px;
                        }

                        .property_title {
                            font-weight: 600;
                            color: $text_color;
                            font-size: 120%;
                            margin: 0;
                        }

                        .property_value {
                            color: $theme_green;
                            font-size: 120%;
                        }

                        &:hover {
                            background-color: $theme_green_hover;

                            .property_title {
                                color: $white_color;
                            }

                            .property_value {
                                color: $white_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.property_sec {
    // padding-top: 60px;
    // background-color: $bg_color;

    .spotlight_box {
        @include d_space;
        margin-bottom: 5px;

        .spotlight {
            font-weight: 700;
        }

        .see_all {
            text-decoration: none;
            color: $white_color;
            background-color: $theme_green;
            padding: 2px 10px;
            border-radius: 15px;


            svg {
                margin-top: -3px;
                margin-left: -4px;
            }

            &:hover {
                background-color: $theme_green_hover;
            }


        }


    }

    .owl-prev,
    .owl-next {
        width: 33px;
        height: 33px;
        background: $white_color !important;
        color: $black-color !important;
        border-radius: 50% !important;
        position: absolute;
        top: 40%;
        transform: translateY(-40%);
        display: grid !important;
        place-content: center;

        span {
            font-size: 35px;
            margin-top: -6px;
        }
    }

    .owl-prev {
        left: 10px;

        span {
            margin-left: -4px;
        }
    }

    .owl-next {
        right: 10px;

        span {
            margin-right: -4px;
        }
    }

    .card-title {
        color: $theme_green;
        font-size: 110%;
        font-weight: 700;
    }

    .card_img_box {
        overflow: hidden;

        img {
            transition: 0.5s;
            cursor: pointer;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .card_box {
        color: $text_color;

        .icons {
            color: $theme_blue;
            background: #4898f62b;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            padding: 3px;
            margin-right: 2px
        }

        .floor_detail {
            @include d_space;

            p {
                margin-bottom: 0;
                font-size: 85%;
            }
        }

        .room_detail {
            @include d_space;

            p {
                margin-bottom: 0;
                font-size: 85%;
            }
        }
    }

    .property_main {

        .more_btn_col {
            margin-top: 20px;
            text-align: center;

            .more_btn {
                border: 0;
                background-color: $theme_green;
                color: $white_color;
                padding: 0;
                width: 200px;
                height: 40px;
                border-radius: 50px;

                &:hover {
                    background-color: $theme_green_hover;
                }
            }
        }
    }
}