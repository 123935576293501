@import "./app-config.scss";

.error_box {
    padding: 50px 0;

    .error_container {
        text-align: center;

        .error_row {
            justify-content: center;

            .error_col {
                .error_icon {
                    svg {
                        color: $theme_green;
                        font-size: 570%;
                    }
                }

                .h1_404 {
                    color: $text_color;
                    font-size: 570%;
                    font-weight: 700;
                }

                .h1_title {
                    color: $text_color;
                    font-size: 300%;
                    font-weight: 700;
                    margin-bottom: 20px;
                }

                .p_para {
                    font-size: 120%;
                    color: $text_light;
                    margin-bottom: 20px;
                }

                .back_btn {
                    display: block;
                    width: 240px;
                    height: 45px;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $theme_green;
                    text-decoration: none;
                    color: $white_color;
                    font-size: 110%;
                    margin: 0 auto;

                    &:hover {
                        background-color: $theme_green_hover;
                    }
                }
            }
        }
    }
}