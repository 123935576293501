.image_gallery_box {
    width: 100%;

    .not_available {
        width: 95%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
    }

    .image-gallery-right-nav,
    .image-gallery-left-nav {
        .image-gallery-svg {
            width: 50px;
            height: 50px;
        }
    }

    @media screen and (min-width: 1200px) {
        .image-gallery-image {
            height: 200px;
            object-fit: cover;
            object-position: center;
        }
    }


    .original_image {
        border-radius: 5px !important;
        overflow: hidden !important;

        img {
            object-fit: cover;
            object-position: center;
            border-radius: 5px;
        }
    }

    .fullscreen {
        .original_image {
            img {
                height: auto;
                object-fit: contain;
            }
        }
    }
}