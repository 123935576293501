@import "../app-config.scss";

.user_img {
    width: 100%;
    height: 50px;
    padding-left: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
        width: 50px;
        height: 100%;
        border-radius: 50%;
    }

    .user_name {
        margin-left: 5px;

        p {
            font-size: 80%;
            color: $text_color;
        }

        .name {
            font-weight: 500;
        }
    }
}