$brand-base-color: #1289A7;
$brand-defaut-border-radius: 4px;
$brand-btn-gray-color: #ddd;
$brand-defaut-tab-radius: 1px;
$brand-btn-primary-bg-color: $brand-base-color;
$brand-btn-primary-boder-color: #218ea9;

$brand-btn-primary-bg-hover-color: $brand-btn-primary-boder-color;
$brand-btn-primary-boder-hover-color: $brand-btn-primary-bg-color;

$brand-btn-link-color: $brand-base-color;
$brand-btn-link-hover-color: $brand-btn-primary-bg-hover-color;
$brand-overlay-bg: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ced4da;
$black-color: #424242;
$bg_color: #f8f8f8;

// new theme colors 

$theme_green : #1dd1a1;
$theme_green_light: #EFFDF5;
$theme_green_hover: #1ea388;
$theme_orange: #ff9800;
$theme_orange_hover: #ff6c00;
$theme_blue: #5372F0;
$theme_blue_hover: #254ded;
$theme_dark_blue: #0E2E50;
$theme_red: #dc3545;
$theme_red_hover: #c12231;
$btn_border: #706f89;
$btn_border_hover: #706f89;
$text_color: #5a5a5a;
$text_light: #a5a5a5;
$white_color: #fff;
$black_color: #000;
$bg_color: #f8f8f8;
$border_color: #dee2e6;