@import "../app-config.scss";
@import "../common/common.scss";

.message_content_container {
    position: fixed;
    z-index: 1000;
    top: 60px;
    bottom: 0;
    right: 0;
    left: auto;
    background: #fafafa;
    padding: 0 0 10px 0;
    width: 290px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;

    .message_title {
        background-color: $white_color;
        width: 100%;
        height: 60px;
        padding: 15px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        flex-direction: row;

        svg {
            margin-right: 10px;
            color: $text_color;
            cursor: pointer;
            display: none;

            @media screen and (max-width : 768px) {
                display: block;
            }
        }

        .tenant_view {
            color: $black_color;
            font-weight: bold;
        }
    }

    .message_content {
        width: 100%;
        height: calc(100% - 110px);
        padding: 10px;
        overflow: auto;
        @include custom_scrollbar;

        .tenant_card_detail {
            width: 100%;
            padding: 10px;
            background: $white_color;
            border: 1px solid #f3f3f3;
            border-radius: 5px;
            margin-bottom: 20px;
            box-shadow: 0 2px 6px #00000020;

            .tenant_name_img {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 0 15px;

                .tenant_img {
                    width: 46px;
                    padding-right: 8px;

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .tenant_name_location {
                    display: flex;
                    flex-direction: column;

                    .tenant_name {
                        font-size: 110%;
                        font-weight: bold;
                        color: $black_color;
                        padding-bottom: 5px;
                    }

                    .tenant_location {
                        color: $text_light;
                    }

                    .property_location_text {
                        font-size: 90%;
                        color: $text_light;
                    }
                }

                .property_owner_slider {
                    overflow: hidden;

                    .owl-stage-outer {
                        border-radius: 5px;

                        img {
                            width: 100%;
                            border-radius: 5px;
                        }
                    }


                    .owl-dots {
                        text-align: center;
                        -webkit-tap-highlight-color: transparent;
                        position: absolute;
                        z-index: 999999999;
                        bottom: 15px;
                        left: 50%;
                        bottom: 15px;
                        transform: translate(-50%, 0);
                        margin-top: 6px;
                        width: 100%;

                        .owl-dot {
                            span {
                                width: 10px;
                                height: 10px;
                                margin: 2px;
                                background: #000000;
                                display: block;
                                transition: 200ms ease;
                                border-radius: 30px;
                                opacity: 0.50;
                            }
                        }

                        .owl-dot.active span {
                            background-color: $theme_blue;
                            opacity: 1;
                        }
                    }

                    .owl-prev,
                    .owl-next {
                        position: absolute;
                        top: 50%;
                        width: 35px;
                        height: 35px;
                        border-radius: 50% !important;
                        transform: translateY(-50%) !important;
                        background: hsla(0, 0%, 100%, 0.75) !important;

                        span {
                            font-size: 50px;
                            color: #333;
                            position: absolute;
                            top: 9px;
                            line-height: 10px;
                        }
                    }

                    .owl-next {
                        right: 15px;

                        span {
                            left: 11px;
                        }
                    }

                    .owl-prev {
                        left: 15px;

                        span {
                            left: 8px;
                        }
                    }
                }
            }

            .rent_detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #f5f5f5;
                padding: 10px 0;

                .rent_label {
                    color: $text_light;
                }

                .rent_value {
                    color: $black_color;
                    font-weight: bold;

                    .pdf {
                        color: red;
                    }

                    .check_icon {
                        color: green;
                    }

                    .close_icon {
                        color: red
                    }
                }
            }

            .rent_accordion {
                .accordion-button {
                    color: $text_color;

                    .angle_icon {
                        position: absolute;
                        right: 15px;
                        transition: 0.5s;
                        color: $text_color;
                        font-size: 110%;
                    }

                    &::after {
                        background-image: none !important;
                    }

                    &:focus {
                        box-shadow: none !important;

                    }

                    &:not(.collapsed) {
                        color: $white_color !important;
                        background-color: $theme_blue !important;
                        box-shadow: none !important;

                        .angle_icon {
                            transform: rotate(-180deg);
                            color: $white_color;
                        }
                    }
                }
            }

        }
    }

    .message_input {
        width: 100%;
        height: 60px;
        z-index: 1000;
        background: $white_color;
        padding: 10px 15px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;

        .remove_tenant {
            width: 100%;
            background-color: $theme_blue;
            color: $white_color;
            border-radius: 5px;
            border: 0;

            svg {
                margin-right: 10px;
            }

            &:hover {
                background-color: $theme_blue_hover;
            }
        }
    }

    .message_input.large_device {
        @media screen and (max-width : 992px) {
            display: none;
        }
    }

    .message_input.small_device {
        display: none;
    }

    .message_input.small_device {
        @media screen and (max-width : 992px) {
            display: block;
            position: inherit;
            padding: 0;
            height: 31.5px;
        }
    }

    @media screen and (max-width : 768px) {
        width: 100%;
        left: 0;
        transform: translateX(-100%);
    }
}

.message_content_container.show_details {
    @media screen and (max-width : 768px) {
        transform: translateX(0);
    }
}

.message_content_container.padding_bottom {
    padding-bottom: 80px;
}