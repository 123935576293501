@import "../app-config.scss";
@import "../common/common.scss";

.table_box {
    padding: 20px;

    .filter_listing {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;

        .form-select {
            width: 200px;
            border-radius: 0;
            margin-left: auto;
            cursor: pointer;
        }
    }

    .table {
        text-align: center;

        th,
        td {
            vertical-align: middle;
        }

        .table_head {
            background-color: $theme_blue;
            color: $white_color;

            .image_th {
                width: 120px;
                min-width: 120px;

            }

            .name_th {
                width: 130px;
                min-width: 130px;
            }

            .rent_th {
                width: 120px;
                min-width: 120px;
            }

            .area_th {
                width: 90px;
                min-width: 90px;
            }

            .bhk_th {
                width: 90px;
                min-width: 90px;
            }

            .status_th {
                width: 180px;
                min-width: 180px;
            }

            .tenant_th {
                width: 140px;
                min-width: 140px;
            }

            .edit_th,
            .view_th {
                width: 50px;
                min-width: 50px;
            }
        }

        .table_body {
            .image_td {
                width: 120px;
                min-width: 120px;

                img {
                    width: 60px;
                    transition: .5s;
                    border-radius: 5px;
                }
            }

            .name_td {
                width: 130px;
                min-width: 130px;
            }

            .rent_td {
                width: 120px;
                min-width: 120px;
            }

            .area_td {
                width: 90px;
                min-width: 90px;
            }

            .bhk_td {
                width: 90px;
                min-width: 90px;
            }

            .status_td {
                width: 180px;
                min-width: 180px;

                .form-select {
                    text-align: center;
                    border: 0;
                    box-shadow: none;
                    cursor: pointer;
                }
            }

            .tenant_td {
                width: 140px;
                min-width: 140px;

                .form-select {
                    text-align: center;
                    border: 0;
                    box-shadow: none;
                    cursor: pointer;
                }

                .add_tenant {
                    background: transparent;
                    border: 0;
                    color: $black_color;
                    cursor: pointer;
                }
            }

            .edit_td,
            .view_td {
                width: 50px;
                min-width: 50px;

                svg {
                    cursor: pointer;
                    color: $text_light;

                    &:hover {
                        color: $theme_green_hover;
                    }
                }
            }
        }
    }
}

.confirmation_modal {
    text-align: center;

    .confirmation_btn {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;

        .btn {
            width: 100px;
            margin: 0 5px;
            border: 0;
        }

        .cancel {
            background-color: $theme_red;

            &:hover {
                background-color: $theme_red_hover;
            }
        }

        .confirm {
            background-color: $theme_blue;

            &:hover {
                background-color: $theme_blue_hover;
            }
        }
    }
}

.add_tenant_modal {
    text-align: left;
}

.remove_tenant_modal {
    text-align: left;

    .remove_tentant_form {
        .form-check {

            input,
            label {
                cursor: pointer;
            }
        }
    }
}