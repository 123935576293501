@import "../app-config.scss";

.career_section {
    .career_category {
        .cart_item {
            display: block;
            height: 100%;
            box-shadow: 0 0 45px rgba(0, 0, 0, .08);
            border: 1px solid transparent;
            transition: .5s;
            padding: 24px;
            border-radius: 2px;

            &:hover {
                border-color: rgba(0, 0, 0, .08);
                box-shadow: none;
            }

            .icon {
                svg {
                    font-size: 3em;
                    color: $theme_green_hover;
                }
            }

            .cart_post {
                font-size: 120%;
                font-weight: 600;
                color: $text_light;
                margin-top: 15px;
            }

            .cart_vacancy {
                font-size: 120%;
                color: $theme_green_hover;
            }
        }
    }
}