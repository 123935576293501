@import "app-config.scss";
@import 'common/common.scss';


.property_detail_content {
    width: 100%;
    height: 100%;

    .scroll_details {
        width: calc(100% - 310px);
        border-right: 1px solid #e5e5e5;
        margin-right: auto;
        position: fixed;
        left: 0;
        top: 60px;
        height: calc(100% - 60px);
        overflow: scroll;
        overflow-x: hidden;
        @include custom_scrollbar;


        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    .property_details {

        background-color: $white_color;
        padding: 60px 20px 20px;
        border-bottom: 1px solid #e5e5e5;

        .image_slider {

            .owl-stage-outer {
                border-radius: 5px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }


            .owl-dots {
                text-align: center;
                -webkit-tap-highlight-color: transparent;
                position: absolute;
                z-index: 999999999;
                bottom: 15px;
                left: 50%;
                bottom: 15px;
                transform: translate(-50%, 0);
                margin-top: 6px;
                width: 100%;

                .owl-dot {
                    span {
                        width: 10px;
                        height: 10px;
                        margin: 2px;
                        background: #000000;
                        display: block;
                        transition: 200ms ease;
                        border-radius: 30px;
                        opacity: 0.50;
                    }
                }

                .owl-dot.active span {
                    background-color: $theme_blue;
                    opacity: 1;
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
                top: 50%;
                width: 35px;
                height: 35px;
                border-radius: 50% !important;
                transform: translateY(-50%) !important;
                background: hsla(0, 0%, 100%, 0.75) !important;

                span {
                    font-size: 50px;
                    color: #333;
                    position: absolute;
                    top: 9px;
                    line-height: 10px;
                }
            }

            .owl-next {
                right: 15px;

                span {
                    left: 11px;
                }
            }

            .owl-prev {
                left: 15px;

                span {
                    left: 8px;
                }
            }
        }

        .details_content {
            .property_title {
                font-size: 130%;
                font-weight: 600;
                color: $text_color;
                margin-bottom: 0;
            }

            .property_location {
                font-size: 95%;
                color: $text_light;
            }

            .price_details {
                @include d_space;

                .price {
                    font-size: 180%;
                    color: $theme_green;
                    font-weight: 700;
                }

                .posted_date {
                    font-size: 90%;
                    color: $text_color;
                    margin-bottom: -10px;
                }
            }

            .property_description {
                padding: 8px 0;

                .property_label {
                    display: flex;
                    align-items: center;
                    color: $text_light;

                    svg {
                        margin-right: 7px;
                        font-size: 90%;
                    }

                    p {
                        font-size: 90%;

                    }
                }

                .property_value {
                    p {
                        font-weight: 700;
                        color: $text_color;
                    }
                }
            }
        }

        .get_btn {
            width: 100%;
            background-color: $theme_blue;
            margin-top: 10px;
            border: 0;
            font-size: 100%;

            &:hover {
                background-color: $theme_blue_hover;
            }

            &::after {
                display: none;
            }
        }

    }

    .scrollpy_section {
        background-color: $bg_color;

        .scrollpy_nav {
            position: sticky;
            position: -webkit-sticky;
            top: 59px;
            z-index: 9;
            box-shadow: 0 4px 4px 0 #00000030;
            background-color: $white_color;
            padding: 10px 20px;
            overflow: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .scroll_link_box {
                .scroll_link {
                    text-decoration: none;
                    color: $black_color;
                    margin: 0 5px;
                    display: inline;
                    cursor: pointer;
                    font-size: 100%;
                    white-space: nowrap;
                    background: #f5f4fd;
                    padding: 5px 12px;
                    border-radius: 25px;
                    border: 1px solid #d8d5f2;

                    &:hover {
                        background-color: $theme_blue;
                        color: $white_color;
                        border-color: transparent;
                    }
                }

                .scroll_active {
                    color: $white_color;
                    background-color: $theme_blue;
                    border-color: transparent;
                }
            }
        }

        .scroll_content {
            padding: 0 20px;

            @media screen and (max-width: 992px) {
                padding-bottom: 50px;
            }
        }

        .content_heading {
            color: $black_color;
            font-size: 120%;
            padding-bottom: 15px;
            font-weight: 700;
        }

        .content_container {
            background: $white_color;
            border-radius: 5px;
            padding: 20px;
            line-height: 1.5;
            margin-bottom: 30px;
            overflow: hidden;

            .content_para {
                color: $text_light;
                font-size: 90%;
            }

            .property_description {
                .overview_col {
                    margin-bottom: 25px;
                    display: flex;


                    .overview_icon {
                        svg {
                            color: $theme_blue;
                            font-size: 100%;
                            margin-right: 10px;
                        }
                    }

                    .overview_value {
                        .label {
                            color: $text_light;
                            font-size: 80%;
                        }

                        .value {
                            font-size: 100%;
                            font-weight: 700;
                            color: $text_color;
                        }
                    }

                    .overview_amenites {
                        display: flex;
                        align-items: center;

                        .amenites_icon {
                            font-size: 100%;
                            color: $theme_blue;
                            margin-right: 10px;
                        }

                        .amenites_value {
                            font-size: 90%;
                            color: $text_color;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.owner_details {
    position: fixed;
    width: 310px;
    height: calc(100% - 60px);
    z-index: 1002;
    top: 60px;
    bottom: 0;
    right: 0;
    left: auto;
    transition: transform .5s;
    -webkit-transition: transform .5s;
    -moz-transition: transform .5s;
    -o-transition: transform .5s;
    background: #f6f6f6;

    .arrow_left {
        display: none;

        @media screen and (max-width: 992px) {
            position: relative;
            z-index: 9;
            display: block;
            background: #f6f6f6;
            padding: 10px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        }
    }

    @media screen and (max-width: 992px) {
        left: 0;
        right: auto;
        transform: translateX(-100%);
    }

    .owner_content {
        width: 100%;
        height: calc(100% - 50px);
        position: relative;
        overflow: auto;
        padding: 10px;
        @include custom_scrollbar;

        @media screen and (max-width: 992px) {
            padding-bottom: 50px;
        }

        .owner_spin {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px;
            padding: 15px;

            .spinner-border {
                border: 2px solid $theme_blue;
                border-right-color: transparent;
            }
        }

        .owner {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px;
            padding: 15px;

            @media screen and (max-width: 992px) {
                margin-bottom: 0;
            }

            .owner_img {
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 100%;
                margin-bottom: 15px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .owner_address {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .name {
                    font-size: 120%;
                    color: $black_color;
                }

                .location {
                    font-size: 100%;
                    color: $text_light;
                }
            }
        }

        .agent_form {
            width: 100%;
            box-shadow: 0 0 17px 0 rgba(0, 0, 0, .08);
            background: $white_color;
            border-radius: 5px;
            padding: 20px;
            margin: 39px 0 20px;

            @media screen and (max-width: 992px) {
                margin-top: 0;
            }

            .form_heading {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                svg {
                    font-size: 130%;
                    font-weight: 500;
                    color: $theme_green;
                    margin-right: 10px;
                }

                p {
                    color: $text_color;
                    font-weight: 500;
                }
            }

            .form_container {
                input {
                    margin-bottom: 10px;
                }

                .form_error {
                    color: $theme_red;
                    margin-top: -10px;
                }

                .contact_now {
                    background-color: $theme_blue;
                    width: 100%;
                    border: 0;
                    margin-top: 9px;

                    &:hover {
                        background-color: $theme_blue_hover;
                    }
                }
            }

        }

        .owner_other_property {
            .title {
                width: 100%;
                font-size: 110%;
                color: $text_color;
                padding-bottom: 10px;
                font-weight: bold;
            }

            .property {
                width: 100%;
                background: #fff;
                border: 1px solid #e5e5e5;
                margin-bottom: 7px;
                padding: 8px;
                border-radius: 5px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }

                .short_info {
                    display: flex;
                    flex-direction: column;

                    .price {
                        color: $theme_green;
                        font-weight: bold;
                    }

                    .address {
                        font-size: 85%;
                        padding: 2px 0;
                        color: $text_color;

                    }

                    .bed_bath {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .bed {
                            svg {
                                font-size: 100%;
                                color: $text_color;
                            }

                            span {
                                color: $text_color;
                                font-size: 90%;
                            }
                        }

                        .bath {
                            svg {
                                font-size: 100%;
                                color: $text_color;
                                margin-bottom: 4px;
                            }

                            span {
                                color: $text_color;
                                font-size: 90%;
                            }
                        }
                    }
                }
            }
        }
    }

    .contact_owner {
        position: sticky;
        background: #fff;
        bottom: 0;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        top: 100%;
        left: 0;
        right: 0;
        box-shadow: 0 -2px 5px #00000030;

        @media screen and (max-width: 992px) {
            top: inherit;
        }

        .btn {
            width: 100%;
            padding: 15px;
            text-align: center;
            border-radius: 0;
            font-size: 110%;
            color: $white_color;
            background-color: $theme_green;
            border: 0;

            &:hover {
                background-color: $theme_green_hover;
            }

            @media screen and (max-width: 992px) {
                padding: 10px;
                font-size: 100%;
            }
        }
    }

}

.owner_details.show_details {
    transform: translateX(0);
}