@import "../app-config.scss";

.clients_sec {
    background-color: $white_color;
    // padding-top: 60px;

    .client_title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 50px;
        color: $theme_orange;
    }

    .client_box {
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 50px 0;
        border-radius: 5px;

        .client_value {
            color: $text_color;
            font-weight: 700;
            font-size: 170%;
        }

        .client_m {
            color: $text_color;
            font-weight: 700;
            font-size: 170%;
        }

        .client_lebel {
            color: $text_color;
            font-weight: 500;
        }
    }
}