@import "../app-config.scss";

.refer_earn_sec {
    .refer_earn {
        .refer_content {
            padding: 20px;

            .refer_title {
                font-size: 160%;
                font-weight: 600;
                color: $text_color;
                text-align: justify;
            }

            .refer_para {
                font-size: 120%;
                color: $text_light;
                text-align: justify;
            }

            .default_btn {
                margin-top: 20px;
                text-transform: capitalize;
            }
        }
    }
}