@import "../app-config.scss";
@import "common.scss";

.property_col {
    .property_item {
        box-shadow: 0 0 30px rgba(0, 0, 0, .08);
        border-radius: 5px;
        overflow: hidden;

        .property_image {
            position: relative;
            overflow: hidden;

            .img_link {
                width: 100%;
            }

            img {
                transition: .5s;
                cursor: pointer;
                width: 100%;
            }

            .sell_badge {
                position: absolute;
                top: 0;
                left: 0;
                margin: 24px;
                padding: 4px 16px;
                border-radius: 5px;
                background-color: $theme_green;
                color: $white_color;
                font-size: 110%;
                text-transform: capitalize;
            }

            .sector_badge {
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $white_color;
                color: $theme_green;
                margin: 0 24px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 4px 16px 0;
                font-size: 120%;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }

        .property_titles {
            padding: 24px 24px 16px;
            position: relative;

            .property_price {
                color: $theme_green;
                font-size: 150%;
                font-weight: 700;
                margin-bottom: 0;
            }

            .property_name {
                font-size: 150%;
                font-weight: 700;
                color: $text_color;
                text-transform: capitalize;
                text-decoration: none;

                &:hover {
                    color: $theme_green;
                }
            }

            .property_locality {
                color: $text_light;
                font-size: 115%;
                margin-top: 0;

                svg {
                    color: $theme_green;
                    font-size: 110%;
                }
            }

            .interested {
                position: absolute;
                top: 0;
                right: 0;
                margin: 24px;
                z-index: 1000;
                background: $theme_green;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;

                .open_heart,
                .full_heart {
                    cursor: pointer;
                    color: $white_color;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &:hover {
                    background: $theme_green_hover;
                }
            }

            .interested_loader {
                position: absolute;
                top: 0;
                right: 0;
                margin: 24px;
                z-index: 1000;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .spinner-border {
                    height: 22px;
                    width: 22px;
                    border: 2px solid $theme_green;
                    border-right-color: transparent;
                }
            }
        }

        .property_amenities {
            display: flex;
            border-top: 1px dashed rgba(0, 185, 142, .3);
            color: $text_light;

            .property_area {
                border-right: 1px dashed rgba(0, 185, 142, .3);
                text-align: center;
                flex: 1 1 auto;
                padding: 8px 0;

                svg {
                    color: $theme_green;
                    font-size: 120%;
                    margin-right: 10px;
                    margin-top: -5px;
                }
            }

            .property_bed {
                border-right: 1px dashed rgba(0, 185, 142, .3);
                text-align: center;
                flex: 1 1 auto;
                padding: 8px 0;

                svg {
                    color: $theme_green;
                    font-size: 120%;
                    margin-right: 10px;
                    margin-top: -5px;

                }
            }

            .property_bath {
                text-align: center;
                flex: 1 1 auto;
                padding: 8px 0;

                svg {
                    color: $theme_green;
                    font-size: 120%;
                    margin-right: 10px;
                    margin-top: -5px;

                }
            }
        }
    }
}