@import "app-config";

footer {
    background-color: $theme_green_hover;
    padding: 50px 0;
    margin-top: 60px;

    @media screen and (max-width: 992px) {
        padding-bottom: 70px;
    }

    .link_box {
        padding: 0;
        margin: 0;

        li {
            list-style: none;

            .link_title {
                color: $white_color;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .link_text {
                display: block;
                text-decoration: none;
                color: $white_color;
                margin: 5px 0;
                cursor: pointer;

                &:hover {
                    color: $black_color;
                }
            }

        }
    }

    .social_link {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: 576px) {
            justify-content: center;
        }

        .social_btn {
            display: grid;
            place-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid $white_color;
            margin: 0 5px 10px;
            transition: .3s;

            &:hover {
                transform: translateY(-5px);
            }

            svg {
                font-size: 130%;
                color: $white_color;
                transition: .1s;

                // &:hover:nth-child(3) {
                //     color: #1877F2;
                // }

                // &:hover:nth-child(4) {
                //     color: #0A66C2;
                // }

                // &:hover:nth-child(5) {
                //     color: #CD201F;
                // }
            }

            // svg:nth-child(1) {
            //     color: #E4405F;
            // }

            // &:hover:nth-child(2) {
            //     color: #25D366;
            // }
        }
    }

    .copyright {
        color: $white_color;

        a {
            color: $white_color;

            &:hover {
                color: $black_color;
            }
        }
    }
}