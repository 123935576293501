@import "../app-config.scss";

.tenant_detail {
    background: #f9f9f9;
    border: 1px solid #ede9e9;
    cursor: pointer;
    width: 100%;
    margin-bottom: 3px;
    border-radius: 10px;
    padding: 10px 7px;
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr 42px;

    .tenant_name_img {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .tenant_img {
            width: 46px;
            padding-right: 8px;

            img {
                width: 100%;
                border-radius: 50%;
            }
        }



        .tenant_name_location {
            display: flex;
            flex-direction: column;

            .tenant_name {
                font-size: 110% !important;
                font-weight: bold;
                color: $black_color;
                padding-bottom: 5px;
            }

            .tenant_location {
                color: $text_light !important;
            }
        }
    }

    .status_active {
        color: $theme_orange !important;
        background: #fff6e7;
        border-radius: 25px;
        padding: 3px 8px;
    }

    .view_btn {
        background-color: transparent;
        font-size: 100%;
        color: $theme_green !important;
        border: 1px solid $theme_green;
        border-radius: 25px;
        padding: 2px 8px;
    }

    &:hover {
        border-color: $theme_green;

        .view_btn {
            background-color: $theme_green;
            color: $white_color !important;
        }
    }

    .date,
    .status,
    .rent {
        color: $text_light !important;
    }
}

.tenant_detail.active_list {
    border-color: $theme_green;

    .view_btn {
        background-color: $theme_green;
        color: $white_color !important;
    }
}