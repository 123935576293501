@import "../app-config.scss";
@import "../common/common.scss";

.profile {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 50px 20px 30px;

    .title {
        color: $black_color;
    }

    .edit_btn {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 61px;
        font-size: 70%;
        color: $text_light;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        background: transparent;
        border: 0;
        border-radius: 15px;
        transition: .3s;

        &:hover {
            transform: translateY(-1px)
        }

        svg {
            margin-right: 5px;
            margin-top: -3px;
            font-size: 110%;
        }
    }

    .profile_img_sec {
        @include profile_section;

        .img_box {
            display: flex;
            align-items: center;

            .image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                border: .5rem solid $white_color;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);

                img {
                    width: 100%;
                }
            }

            .name_box {
                margin-left: 10px;

                .name {
                    font-size: 140%;
                    margin: 0;
                    color: $black_color;
                }

                p {
                    color: $text_light;
                }
            }
        }
    }

    .per_title {
        font-weight: 700;
        margin-bottom: 20px;
        color: $black_color;
    }

    .per_label {
        font-size: 90%;
        color: $text_light;
    }

    .per_value {
        color: $black_color;
        font-weight: 500;
    }

    .personal_info {
        @include profile_section;
    }

    .address_info {
        @include profile_section;
    }

    .security_info {
        @include profile_section;
    }
}

.profile_image_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image_preview {
        display: grid;
        place-content: center;
        margin-bottom: 5px;

        img {
            max-width: 100%;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
        }
    }
}


.btn_box {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .profile_pics {
        background-color: transparent;
        border-radius: 5px;
        color: $theme_blue;
        border: 1px solid $theme_blue;
        width: 100px;
        height: 30px;
        text-align: center;
        display: grid;
        place-content: center;
        margin-bottom: 0;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
            background-color: $theme_blue;
            color: $white_color;
        }

        input {
            display: none;
        }
    }
}