@import "../app-config.scss";

.blog_detail {
    .blog_detail {
        .blog_details_warpper {
            .details_image {
                img {
                    width: 100%;
                }
            }

            .details_content {
                background: #f1f1f1;
                padding: 35px 40px;
                border-radius: 0 0 10px 10px;

                .details_title {
                    color: $black_color;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .details_date {
                    font-size: 120%;
                    font-weight: 600;
                    color: $text_color;
                    margin-bottom: 5px;
                }

                .details_para {
                    font-size: 115%;
                    color: $text_color;
                    margin-bottom: 16px;
                }

                .blockquote {
                    margin: 35px 0;
                    max-width: 660px;
                    background-color: $theme_green;
                    padding: 30px 40px;
                    position: relative;
                    color: $white_color;
                    font-size: 15px;
                    font-style: italic;
                }

                .blog_marketing_wrap {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    margin-top: 30px;

                    .tags {
                        strong {
                            font-size: 120%;
                            margin-right: 7px;
                        }

                        .tag_link {
                            text-decoration: none;
                            color: $text_color;
                            font-size: 110%;
                            display: inline-block;
                            text-transform: capitalize;
                            margin: 0 3px;

                            &:hover {
                                color: $theme_green;
                            }
                        }
                    }

                    .share_social {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        strong {
                            font-size: 120%;
                            margin-right: 7px;
                        }

                        .share_social_ul {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            .social_li {
                                margin-left: 20px;

                                &:first-child {
                                    margin-left: 10px;
                                }

                                .social_a {
                                    display: inline-block;
                                    font-size: 100%;

                                    svg {
                                        color: $black_color;

                                        &:hover {
                                            color: $theme_green;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        .widget {
            position: sticky;
            top: 100px;
            padding-left: 20px;

            .single_widget {
                .widget_title {
                    font-weight: 600;
                    position: relative;
                    padding: 6px;
                    margin-bottom: 0;
                    display: inline-block;
                    text-transform: capitalize;

                    &::before {
                        position: absolute;
                        content: "";
                        background: $theme_green;
                        width: 2px;
                        height: 30px;
                        left: 0;
                        bottom: 0;
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        background: $theme_green;
                        width: 100%;
                        height: 2px;
                        left: 0;
                        bottom: 0;
                    }
                }

                .categories_ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    margin-top: 15px;


                    .categories_li {

                        margin: 5px 0;

                        .categories_name {
                            text-decoration: none;
                            color: $text_color;
                            font-size: 110%;
                            font-weight: 600;
                            display: flex;
                            justify-content: space-between;

                            &:hover {
                                color: $theme_green;
                            }

                            span {
                                font-size: 100%;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .related_post {
                    margin-top: 30px;

                    .single_related {
                        display: flex;
                        margin-bottom: 30px;

                        .single_related_image {
                            min-width: 120px;
                            width: 120px;

                            .link_img {
                                width: 100%;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }

                        .single_related_contents {
                            padding-left: 15px;

                            .single_title {
                                font-weight: 600;
                                color: $black_color;
                                font-size: 110%;
                                margin: 0;
                                padding: 0;

                                &:hover {
                                    color: $theme_green_hover;
                                }
                            }

                            .single_date {
                                font-weight: 600;
                                color: $text_color;
                            }

                            .single_para {
                                color: $text_light;
                                font-size: 100%;

                            }
                        }
                    }
                }
            }

            .widget_recent {
                margin-top: 30px;
            }

            .widget_search {
                margin-bottom: 30px;

                .search_form {
                    margin-top: 20px;
                }
            }
        }

    }
}